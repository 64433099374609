.account-dialog {
  min-width: 96vw;
}

.account-dialog .dialog-header {
  width: 100%;
}

.account-dialog .p-dialog-header {
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  background-color: var(--dark-grey);
  height: 3.813rem;
  width: 100%;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  width: 100%;
}

.account-dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #ffffff;
  margin-left: auto;
}

.account-dialog .p-fileupload {
  margin: 10px 0;
  height: 67vh;
}

.account-dialog .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
}

.account-dialog .p-fileupload-buttonbar .p-button {
  border-radius: 2rem;
  background: #4962f3;
  border: 1px solid #4962f3;
  margin-top: 10px;
}

.account-dialog .p-fileupload {
  border-radius: 10px;
}

.account-dialog .p-fileupload .p-fileupload-content {
  background: none;
  border: 1px dashed #404040;
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 10px;
  margin-top: 20px;
}

.account-dialog .p-fileupload .p-fileupload-content.dragging {
  background-color: rgba(0, 0, 0, 0.1);
}

.account-dialog .p-fileupload .p-fileupload-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.account-dialog .text-uppercase {
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.account-dialog .p-fileupload-choose {
  margin-left: auto;
}

.account-dialog .p-fileupload-files {
  width: 50%;
}

.account-dialog .p-fileupload-row div:first-child {
  display: none;
}

.account-dialog .p-fileupload-row div:nth-child(3) {
  max-width: 100px;
}

.account-dialog .p-fileupload-row div:last-child {
  max-width: 50px;
}

.account-dialog .p-button.p-component.p-button-icon-only {
  color: gray;
  background: none;
  border: none;
}

.account-dialog .p-progressbar {
  display: none;
}

.account-dialog .type-dropdown {
  position: absolute;
  left: 20px;
  top: 80px;
  z-index: 2;
}

.account-dialog .type-dropdown .p-inputtext {
  font-size: 0.9rem;
}

.account-dialog .p-message-close {
  margin-left: 1.2rem;
}

.account-dialog .p-message-close-icon {
  font-size: 0.8rem;
}

.account-dialog .import-dialog .pi-plus:before {
  content: "\e964";
}

.account-dialog .pi-upload:before {
  content: "\e944";
}

.account-dialog.loading .pi-upload:before {
  content: "\e926";
}

.account-dialog.loading .pi-upload {
  webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}

.account-dialog .pi.upload-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}
