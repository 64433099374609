<template>
  <div id="account-tab" class="p-fluid form-input-container">
    <div class="p-formgrid p-grid">
      <ValidatedInput rules="required" label="Right">
        <Dropdown
          v-model="accountForm.right_id"
          :disabled="readonly"
          :options="rights"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
        />
      </ValidatedInput>
      <ValidatedInput label="Dashboard User ID">
        <InputText
          v-model="accountForm.dashboard_id"
          type="number"
          :readonly="readonly"
          @blur="fetchDashboardUser"
        />
      </ValidatedInput>
      <ValidatedInput label="Name" rules="required">
        <InputText
          v-model="accountForm.name"
          :readonly="readonly"
          type="text"
        />
      </ValidatedInput>
    </div>

    <Divider class="p-mb-5" />

    <div class="p-formgrid p-grid">
      <ValidatedInput rules="required" label="Platforms">
        <div class="p-mt-2 p-grid">
          <div
            v-for="item in allPlatforms"
            :key="item"
            class="p-col-2 p-field-checkbox p-mr-3"
          >
            <Checkbox
              :id="item"
              name="platform"
              :value="item"
              v-model="accountForm.platforms"
              :disabled="readonly"
            />
            <label :for="item">{{ item }}</label>
          </div>
        </div>
      </ValidatedInput>
    </div>

    <Divider class="p-mb-5 p-mt-0" />

    <div class="p-formgrid p-grid">
      <ValidatedInput rules="required" label="Contract Status" class="p-col-3">
        <Dropdown
          v-model="accountForm.contract_status_id"
          :disabled="readonly"
          :options="contractStatuses"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
        />
      </ValidatedInput>
      <ValidatedInput label="Contract Date" rules="required" class="p-col-3">
        <DatePick
          :editable="!readonly"
          format="YYYY-MM-DD"
          v-model="accountForm.contract_date"
        ></DatePick>
      </ValidatedInput>
      <ValidatedInput
        label="Expiration Date"
        :rules="expirationRequirement"
        class="p-col-3"
      >
        <DatePick
          :editable="!readonly"
          format="YYYY-MM-DD"
          v-model="accountForm.expiration_date"
        ></DatePick>
      </ValidatedInput>
      <ValidatedInput label="First Contract Date" class="p-col-3">
        <DatePick
          :editable="!readonly"
          format="YYYY-MM-DD"
          v-model="accountForm.initial_contract_date"
        ></DatePick>
      </ValidatedInput>

      <ValidatedInput
        label="Contact Email"
        rules="required|email"
        class="p-col-3"
      >
        <span class="p-input-icon-right">
          <InputText
            v-model="accountForm.contact_email"
            :readonly="readonly"
            type="email"
            :disabled="isDashboardUserLoading"
          />
          <i v-if="isDashboardUserLoading" class="pi pi-spin pi-spinner" />
        </span>
      </ValidatedInput>
      <ValidatedInput label="First Contact Date" class="p-col-3">
        <DatePick
          :editable="!readonly"
          format="YYYY-MM-DD"
          v-model="accountForm.first_contact_date"
        ></DatePick>
      </ValidatedInput>
      <ValidatedInput label="Contract Office" rules="required" class="p-col-3">
        <Dropdown
          v-model="accountForm.contract_office_id"
          :disabled="readonly"
          :options="offices"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
        />
      </ValidatedInput>
      <ValidatedInput label="Team" rules="required" class="p-col-3">
        <Dropdown
          v-model="accountForm.team_id"
          :disabled="readonly"
          :options="accountTeams"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
        />
      </ValidatedInput>
      <ValidatedInput
        label="Tier"
        class="p-col-3"
        :rules="!isChinaPlatform ? 'required' : ''"
      >
        <Dropdown
          v-model="accountForm.tier_id"
          :disabled="readonly"
          :options="tiers"
          :showClear="isChinaPlatform"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
        />
      </ValidatedInput>

      <ValidatedInput label="Previous MCN" class="p-col-3">
        <InputText
          v-model="accountForm.previous_mcn"
          :readonly="readonly"
          type="text"
        />
      </ValidatedInput>
      <ValidatedInput label="Third Party" class="p-col-3">
        <InputText
          v-model="accountForm.third_party"
          :readonly="readonly"
          type="text"
        />
      </ValidatedInput>
      <ValidatedInput
        label="Partner Organization"
        class="p-col-3"
        :isNew="true"
      >
        <InputText
          v-model="accountForm.partner_organization"
          :readonly="readonly"
          type="text"
        />
      </ValidatedInput>
    </div>

    <Divider class="p-mb-5" />

    <div class="notice p-mb-2">
      <i>Only for Japan</i>
    </div>
    <div class="p-formgrid p-grid">
      <ValidatedInput label="Status (JP)">
        <InputText
          v-model="accountForm.status"
          :readonly="readonly"
          type="text"
        />
      </ValidatedInput>
      <ValidatedInput label="Yomi (JP)">
        <InputText
          v-model="accountForm.yomi"
          :readonly="readonly"
          type="text"
        />
      </ValidatedInput>
      <ValidatedInput label="Class (JP)">
        <Dropdown
          v-model="accountForm.class_id"
          :disabled="readonly"
          :options="classes"
          :showClear="true"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
        />
      </ValidatedInput>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce } from "lodash";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  readAccountTeams,
  readClasses,
  readContractStatuses,
  readOffices,
  readRights,
  readTiers,
} from "@/store/main/getters";
import { api } from "@/api";
import { DashboardUser } from "@/interfaces";

@Component
export default class AccountEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("account") public accountForm!: any;

  public platforms = [
    "YouTube",
    "Instagram",
    "Facebook",
    "TikTok",
    "Twitter",
    "Twitch",
    "LikeApp",
    "Douyin",
    "CHZZK",
  ];

  public chinaPlatforms = [
    "Bilibili",
    "Bilibili Premiere",
    "Xigua",
    "Weibo",
    "Red",
    "iQIYI",
  ];

  public isDashboardUserLoading = false;
  public dashboardUser: DashboardUser | null = null;

  public get offices() {
    return readOffices(this.$store);
  }

  public get accountTeams() {
    return readAccountTeams(this.$store);
  }

  public get contractStatuses() {
    return readContractStatuses(this.$store);
  }

  public get tiers() {
    return readTiers(this.$store);
  }

  public get rights() {
    return readRights(this.$store);
  }

  public get classes() {
    return readClasses(this.$store);
  }

  public get isChinaPlatform() {
    return this.accountForm.type === "China Platform";
  }

  public get allPlatforms() {
    if (this.isChinaPlatform) {
      return [...this.platforms, ...this.chinaPlatforms];
    }
    return this.platforms;
  }

  public get expirationRequirement() {
    if ([4, 5].includes(this.accountForm.right_id)) {
      return "required";
    }
    return "";
  }

  @Watch("accountForm.right_id", { immediate: true })
  public changeAccountType(input: number, original: number) {
    let accountType: string;
    let right;
    if (input) {
      right = this.rights.filter((item) => item.id === input)[0];
      if (right) {
        if (["Network", "Music (Channel + Asset)"].includes(right.name)) {
          accountType = "YouTube Channel";
        } else if (right.name === "Podcast") {
          accountType = "Podcast";
        } else if (right.name === "China Platform") {
          accountType = "China Platform";
        } else {
          accountType = "Clip Licensing";
        }
      }
      if (!this.createView && this.accountForm.type !== accountType) {
        this.accountForm.right_id = original;
        this.$toast.add({
          severity: "warn",
          group: "bl",
          summary: "Incompatible Account Right",
          detail: `This account is a ${this.accountForm.type}, which is incompatible with the "${right.name}" Right.\nPlease create a new account to switch to that Right.`,
          life: 10000,
        });
        return;
      }
    } else {
      accountType = "YouTube Channel";
    }
    this.accountForm.right_id = input;
    this.accountForm.type = accountType;
  }

  public async fetchDashboardUser() {
    if (
      !this.createView ||
      !this.accountForm.dashboard_id ||
      this.dashboardUser?.id === parseInt(this.accountForm.dashboard_id)
    ) {
      return;
    }

    this.isDashboardUserLoading = true;
    this.$emit("dashboard-user-updating");
    const response = await api.getDashboardUser(this.accountForm.dashboard_id);
    if (response && response.data) {
      this.dashboardUser = response.data;
      const { email, youtube_channels } = this.dashboardUser;
      this.$set(this.accountForm, "contact_email", email);
      this.$emit("dashboard-user-updated", this.dashboardUser);
    }
    this.isDashboardUserLoading = false;
  }

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/account`);
    }
  }
}
</script>

<style scoped>
.notice {
  font-size: 0.85rem;
}
</style>
