.file-upload .p-fileupload .p-fileupload-buttonbar {
  display: none;
}

.file-upload .p-fileupload .p-fileupload-content {
  background: none;
  border: 1px dashed #404040;
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 10px;
  margin-top: 16px;
}

.file-upload .p-fileupload .p-fileupload-content.dragging {
  background-color: rgba(0, 0, 0, 0.1);
}

.file-upload .p-fileupload .p-fileupload-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-upload .p-fileupload-files {
  width: 50%;
}

.file-upload .p-fileupload-row div:first-child {
  display: none;
}

.file-upload .p-fileupload-row div:nth-child(3) {
  max-width: 100px;
}

.file-upload .p-fileupload-row div:last-child {
  max-width: 50px;
}

.file-upload .p-button.p-component.p-button-icon-only {
  color: gray;
  background: none;
  border: none;
}

.file-upload .p-progressbar {
  display: none;
}

.file-upload.p-message-close {
  margin-left: 1.2rem;
}

.file-upload .p-message-close-icon {
  font-size: 0.8rem;
}

.file-upload .pi.upload-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
