<template>
  <Dialog
    class="account-dialog"
    :closable="false"
    :visible.sync="isOpenSync"
    maximizable
    @hide="hide"
    modal
  >
    <template #header>
      <div class="p-d-flex p-ai-center dialog-header">
        <span>Your Drafts</span>
        <button
          @click="hide"
          aria-label="close"
          type="button"
          tabindex="-1"
          class="p-dialog-header-icon p-dialog-header-close p-link"
        >
          <span class="p-dialog-header-close-icon pi pi-times-circle"></span>
        </button>
      </div>
    </template>
    <div class="p-grid p-m-0 content-wrapper h-full p-py-4">
      <div
        v-for="draft in drafts"
        :key="draft.id"
        class="draft-item"
        @click="() => editDraft(draft.id)"
      >
        <Card class="draft-item">
          <template #content>
            <div><i class="pi pi-file draft-icon" /></div>
            <div class="p-text-bold p-py-1">
              {{ draft.data.account.name || "Untitled" }}
            </div>
            <div class="p-pt-2 draft-text">
              Last Edited: <br />
              {{ formateDate(draft.updated_at) }}
            </div>
            <div
              v-if="getExpiringInDays(draft.updated_at) < 6"
              class="draft-text p-pt-2 expiry-warning"
            >
              {{
                `Expiring in ${getExpiringInDays(draft.updated_at)} ${
                  getExpiringInDays(draft.updated_at) > 1 ? "days" : "day"
                }`
              }}
            </div>
          </template>
        </Card>
      </div>
    </div>
    <template #footer>
      <div class="p-d-flex p-pt-2">
        <div>Total Number of Drafts: {{ drafts.length }}</div>
        <div class="flex-grow">
          <Button
            icon="pi pi-plus"
            label="Add New"
            class="p-button-rounded p-button-main"
            @click="addNewAccount"
          >
          </Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { AccountDraft } from "@/interfaces";
import "@/assets/styles/account-dialog.css";

@Component
export default class MastersheetDraft extends Vue {
  @Prop()
  public drafts: Array<AccountDraft>;

  @PropSync("isOpen", { type: Boolean })
  public isOpenSync!: boolean;

  public hide() {
    this.isOpenSync = false;
  }

  public formateDate(datetime) {
    return datetime.substring(0, 16).replace("T", " ");
  }

  public getExpiringInDays(lastUpdated) {
    const timeDiff = new Date().getTime() - new Date(lastUpdated).getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return 7 - daysDiff;
  }

  public addNewAccount() {
    this.$emit("add-new");
  }

  public editDraft(id: number) {
    this.$emit("edit-draft", id);
  }
}
</script>

<style scoped>
.content-wrapper {
  height: 67vh;
  max-width: 90vw;
}

.draft-item {
  height: 180px;
  width: 180px;
  margin: 0 20px 20px 0;
  cursor: pointer;

  .draft-icon {
    font-size: 2rem;
  }

  .draft-text {
    font-size: 0.85rem;
  }

  .expiry-warning {
    color: red;
  }
}
</style>
