<template>
  <div id="manager-tab" class="form-input-container">
    <ValidatedInput rules="" label="" name="members" hidden>
      <input v-model="members" />
    </ValidatedInput>
    <Dialog :visible.sync="dialog" style="padding: 1rem">
      <DataView :value="namedMappingGroups" layout="grid">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            Manager Group Templates
          </div>
        </template>
        <template #grid="slotProps">
          <div class="p-col-4 p-p-3">
            <div
              class="grid-item-container"
              @click="loadMappingGroupTemplate(slotProps.data)"
            >
              <div class="p-mb-0 p-text-bold">{{ slotProps.data.name }}</div>
              <div class="p-mb-2 p-text-light">
                {{ slotProps.data.description }}
              </div>
              <div>
                <div
                  v-for="member in slotProps.data.members"
                  :key="member.id"
                  class="p-grid p-d-flex p-ai-center p-jc-between p-my-1"
                >
                  <div class="p-col">
                    {{ member.first_name }}
                    {{ member.last_name }}
                  </div>
                  <div class="p-col-fixed" style="width: 3rem">
                    <country-flag
                      :country="
                        offices.filter(
                          (item) => member.office_id === item.id
                        )[0].code
                      "
                      size="small"
                    />
                  </div>
                  <div class="p-col">
                    {{ getRoleName(member.role_id) }}
                  </div>
                  <div class="p-col-fixed">
                    {{ member.split | percentage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DataView>
    </Dialog>
    <ValidationProvider
      immediate
      v-slot="{ errors }"
      rules="managerSplitTotal:@members"
      vid="managerSplitTotal"
      ref="managerList"
    >
      <DataView :value="members" layout="list" class="member-grid">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            Managed by
            <Button
              v-if="!readonly"
              class="p-button-danger"
              @click="resetMembers"
            >
              Reset
              <i class="pi pi-replay" style="margin-left: 0.75rem" />
            </Button>
          </div>
        </template>
        <template #list="slotProps">
          <div class="p-col-12">
            <div class="member-grid-item-container p-grid p-ai-center">
              <span class="p-col">
                <country-flag
                  :country="
                    offices.filter(
                      (item) => slotProps.data.office_id === item.id
                    )[0].code
                  "
                  size="small"
                  class="p-mr-0"
                />
                {{
                  [slotProps.data.first_name, slotProps.data.last_name].join(
                    " "
                  )
                }}
              </span>
              <span class="p-col-fixed" style="width: 11rem">
                {{ slotProps.data.email }}
              </span>
              <span class="p-col-fixed" style="width: 5rem">
                {{ teamName(slotProps.data.team_id) }}
              </span>
              <span class="p-col">
                <Dropdown
                  v-model="slotProps.data.role_id"
                  :disabled="readonly"
                  :options="managerRoles"
                  optionLabel="name"
                  optionValue="id"
                  appendTo="body"
                />
              </span>
              <span class="p-col-fixed">
                <InputNumber
                  :min="0"
                  :max="100"
                  mode="decimal"
                  :minFractionDigits="1"
                  :maxFractionDigits="2"
                  :value="slotProps.data.split * 100"
                  @input="
                    ($event) => {
                      slotProps.data.split = $event / 100;
                      $refs.managerList.validate();
                    }
                  "
                  :ref="'vi-' + slotProps.data.id + '-input'"
                  :readonly="readonly"
                  suffix="%"
                  class="percentage-input"
                />
              </span>
              <span v-if="!readonly" class="p-col-fixed" style="width: 1rem">
                <i
                  class="pi pi-trash"
                  style="cursor: pointer"
                  @click="removeMember(slotProps.data.id)"
                  v-tooltip.bottom="'Delete'"
                />
              </span>
            </div>
          </div>
        </template>
      </DataView>
      <div
        v-if="!readonly && errors.length"
        class="form-field-error-message p-ml-2 p-mt-2"
      >
        {{ errors[0] }}
      </div>
    </ValidationProvider>
    <div v-if="!readonly" class="p-d-flex p-jc-between p-mt-3">
      <autocomplete
        :search="searchManagers"
        placeholder="Search for a manager to add"
        aria-label="Search"
        :debounceTime="300"
        :autoSelect="true"
        id="autocomplete-search-managers"
        base-class="autocomplete-search"
        @submit="selectManager"
        ref="autocomplete-search"
      >
        <template #result="{ result, props }">
          <li
            v-bind="props"
            class="autocomplete-result"
            style="padding-bottom: 0.5rem"
          >
            <div class="p-d-flex p-ai-center p-jc-between">
              <div class="p-d-flex p-ai-center">
                <avatar
                  :username="result.first_name"
                  :src="result.photo_url"
                  class="p-mr-3"
                />
                <span>
                  {{ result.first_name }}
                  {{ result.last_name }}
                </span>
              </div>
              <country-flag
                :country="
                  offices.filter((item) => result.office_id === item.id)[0].code
                "
                size="small"
              />
            </div>
          </li>
        </template>
      </autocomplete>
      <Button @click="dialog = true">
        <i class="pi pi-users" style="font-size: 14px; margin-right: 1rem" />
        Load manager group templates
      </Button>
    </div>

    <DataTable
      v-if="isAlpha && !createView"
      class="p-my-4"
      :value="managerHistory"
      :loading="managerHistoryIsLoading"
    >
      <template #empty>No records found.</template>
      <Column header="Manager History">
        <template #body="slotProps">
          <table>
            <tr v-for="manager in slotProps.data.managers">
              <td width="50%">
                {{ `${manager.manager.name} (${manager.manager.email})` }}
              </td>
              <td align="center" width="25%">{{ manager.role }}</td>
              <td align="center">{{ manager.split }}</td>
            </tr>
          </table>
        </template>
      </Column>
      <Column field="created_at" header="Date Deleted">
        <template #body="slotProps">
          {{ dayjs(slotProps.data.created_at).format("YYYY-MM-DD") }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { readIsAlpha } from "@/store/auth/getters";
import {
  dispatchListManagerRoles,
  dispatchListManagers,
  dispatchListMappingGroups,
  dispatchListOffices,
  dispatchListTeams,
} from "@/store/main/actions";
import {
  readManagerRoles,
  readManagers,
  readMappingGroups,
  readOffices,
  readTeams,
} from "@/store/main/getters";
import DataView from "primevue/dataview";
import { api } from "@/api";
import Fuse from "fuse.js";
import { extend } from "vee-validate";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

extend("managerSplitTotal", {
  params: ["managers"],
  // @ts-ignore
  validate(value, { managers }) {
    if (managers.length) {
      const total = managers.map((item) => item.split).reduce((a, b) => a + b);
      return total == 1 || total == 0;
    }
    return false;
  },
  message: "Splits must add up to 100%",
});

@Component({
  components: {
    DataView: DataView,
    Autocomplete,
  },
})
export default class ManagersEdit extends Vue {
  @Prop() public readonly accountId: number | undefined;
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  public members = [];
  public loaded = false;
  public filteredManagers = [];
  public fuse = null;
  public originalMembersString = "[]";
  public dialog = false;
  public namedMappingGroups = [];

  public managerHistory = [];
  public managerHistoryIsLoading = false;

  private filterMembersOptions = {
    includeScore: true,
    keys: ["first_name", "last_name", "nickname", "email"],
  };

  public get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public get managers() {
    return readManagers(this.$store);
  }

  public get offices() {
    return readOffices(this.$store);
  }

  public get teams() {
    return readTeams(this.$store);
  }

  public get mappingGroups() {
    return readMappingGroups(this.$store);
  }

  public teamName(teamId: number) {
    if (teamId) {
      return this.teams.filter((item) => teamId === item.id)[0].name;
    }
    return null;
  }

  public get managerRoles() {
    return readManagerRoles(this.$store);
  }

  public getRoleName(roleId: number) {
    if (roleId) {
      const role = this.managerRoles.filter((item) => item.id === roleId)[0];
      if (role) {
        return role.name;
      }
      return "";
    }
    return "";
  }

  public searchManagers(event) {
    return this.fuse.search(event).map((item) => item.item);
  }

  public async fetchManagers(managers = null) {
    let members = managers;
    if (!managers) {
      const response = await api.listAccountManagers(this.accountId);
      if (response) {
        members = response.data;
      }
    }

    this.members = members.map((member) => {
      const manager = this.managers.find(
        (manager) => member.manager_id === manager.id
      );
      return {
        ...manager,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    this.originalMembersString = JSON.stringify(this.members);
  }

  public async fetchManagerHistory() {
    if (!this.isAlpha) {
      return;
    }

    this.managerHistoryIsLoading = true;
    const response = await api.listAccountPreviousManagers(this.accountId);
    if (response) {
      this.managerHistory = response.data.map((history) => {
        const managers = history.managers.map((account_manager) => {
          const manager = this.managers.find(
            (manager) => account_manager.manager_id === manager.id
          );
          return {
            manager: {
              name: `${manager.first_name} ${manager.last_name}`,
              email: manager.email,
            },
            split: `${account_manager.split * 100}%`,
            role: this.managerRoles.find(
              (role) => role.id === account_manager.role_id
            ).name,
          };
        });
        return {
          id: history.id,
          managers,
          created_at: history.created_at,
        };
      });
    }
    this.managerHistoryIsLoading = false;
  }

  public loadMappingGroupTemplate(value) {
    this.members = value.members.map((member) => {
      const manager = this.managers.filter(
        (manager) => member.manager_id === manager.id
      )[0];
      return {
        ...manager,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    this.dialog = false;
  }

  public async saveManagers(accountId: number = null) {
    const create = !!accountId;
    accountId = accountId ? accountId : this.accountId;
    const payload = this.members.map((member) => {
      return {
        account_id: accountId,
        manager_id: member.id,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    // @ts-ignore
    if (payload.map((item) => item.split).reduce((a, b) => a + b) != 1) {
      payload.forEach((manager) => {
        manager.split = 1 / payload.length;
      });
    }

    // nothing to update
    if (this.originalMembersString === JSON.stringify(this.members)) {
      return true;
    }

    const response = await api.updateAccountManagers(accountId, payload);
    if (response.status == 200) {
      if (!create) {
        await Promise.all([this.fetchManagers(), this.fetchManagerHistory()]);
      }
      return true;
    }
    return false;
  }

  public selectManager($event) {
    if (!this.members.find((manager) => manager.id === $event.id)) {
      this.members.push({ ...$event, role_id: 4, split: 0 });
    }
    (this.$refs["autocomplete-search"] as HTMLInputElement).value = "";
  }

  public removeMember(managerId: number) {
    this.members = this.members.filter((member) => member.id !== managerId);
  }

  public resetMembers() {
    this.members = JSON.parse(this.originalMembersString);
  }

  public async created() {
    if (!this.createView) {
      window["analytics"]["page"](`/mastersheet/${this.accountId}/managers`);
    }
    if (!this.offices.length) {
      await dispatchListOffices(this.$store);
    }
    if (!this.teams.length) {
      await dispatchListTeams(this.$store);
    }

    await dispatchListManagers(this.$store);
    this.fuse = new Fuse(this.managers, this.filterMembersOptions);

    await dispatchListMappingGroups(this.$store);
    this.namedMappingGroups = this.mappingGroups.map((mappingGroup) => {
      const data = mappingGroup;
      data.members = mappingGroup.template.members.map((member) => {
        const filtered = this.managers.filter(
          (manager) => manager.id == member.manager_id
        );
        const manager = filtered.length ? filtered[0] : {};
        return { ...member, ...manager };
      });
      return data;
    });

    await dispatchListManagerRoles(this.$store);

    if (!this.createView) {
      await this.fetchManagers();
      await this.fetchManagerHistory();
    }
  }

  public beforeDestroy() {
    this.$toast.removeGroup("bl");
  }
}
</script>

<style>
.member-grid .p-dataview-content {
  max-height: 30rem;
  max-width: 72vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.member-grid-item-container {
  padding: 1rem 2rem 0.5rem 1rem;
}

.grid-item-container {
  background-color: var(--pale-grey-three);
  height: 100%;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}

.grid-item-container:hover {
  background-color: var(--pale-grey-two);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.member-grid-item-container span {
  margin-right: 1rem;
}

.manager-search-box input {
  width: 20rem;
}

.percentage-input input {
  width: 5rem;
}

#autocomplete-search-managers {
  width: 20rem;
}
</style>
