<template>
  <svg
    id="Component_3_2"
    data-name="Component 3 – 2"
    xmlns="http://www.w3.org/2000/svg"
    width="15.289"
    height="15.135"
    style="cursor: pointer"
    viewBox="0 0 15.289 15.135"
    class="edit-icon"
    @click="invokeParentMethod"
  >
    <path
      id="Path_76"
      data-name="Path 76"
      class="cls-1"
      d="M16.005,2a.522.522,0,0,1,0,.737L14.917,3.83,12.829,1.742,13.918.652a.522.522,0,0,1,.738,0L16.005,2ZM14.179,4.566,12.091,2.479,4.98,9.591a.522.522,0,0,0-.126.2l-.84,2.52a.261.261,0,0,0,.33.33l2.52-.84a.522.522,0,0,0,.2-.125l7.111-7.112Z"
      transform="translate(-0.869 -0.5)"
    />
    <path
      id="Path_77"
      data-name="Path 77"
      class="cls-2"
      d="M1,14.047a1.566,1.566,0,0,0,1.566,1.566H14.047a1.566,1.566,0,0,0,1.566-1.566V7.784a.522.522,0,1,0-1.044,0v6.262a.522.522,0,0,1-.522.522H2.566a.522.522,0,0,1-.522-.522V2.566a.522.522,0,0,1,.522-.522H9.35A.522.522,0,0,0,9.35,1H2.566A1.566,1.566,0,0,0,1,2.566Z"
      transform="translate(-1 -0.478)"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class EditIcon extends Vue {
  public invokeParentMethod() {
    this.$router.push(`/mastersheet/${this["params"]["value"]}`);
  }
}
</script>

<style scoped>
.cls-1,
.cls-2 {
  fill: #409eff;
}

.cls-2 {
  fill-rule: evenodd;
}

.edit-icon {
  margin-top: 1rem;
}
</style>
