<template>
  <div class="account-files">
    <DataTable
      class="p-datatable-sm"
      scrollHeight="30vh"
      :scrollable="true"
      :loading="isListLoading"
      :value="files"
    >
      <template #empty> No files found. </template>
      <Column header="File">
        <template #body="slotProps">
          <span :class="`p-mr-2 pi ${slotProps.data.icon}`"></span>
          {{ slotProps.data.description }}
        </template>
      </Column>
      <Column header="Date added">
        <template #body="slotProps">
          {{ dayjs(slotProps.data.created_at).format("YYYY-MM-DD") }}
        </template>
      </Column>
      <Column field="id" header="Added by">
        <template #body="slotProps">
          {{
            `${slotProps.data.uploader_first_name} ${slotProps.data.uploader_last_name}`
          }}
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <a
            v-if="slotProps.data.url"
            :href="
              slotProps.data.url.startsWith('http') ||
              slotProps.data.url.startsWith('https')
                ? slotProps.data.url
                : `https://${slotProps.data.url}`
            "
            target="_blank"
            class="file-link"
          >
            <Button
              v-tooltip.bottom.bottom="'Open link'"
              icon="pi pi-external-link"
              class="p-button-text p-button-icon-only p-button-rounded"
            />
          </a>
          <Button
            v-else
            :disabled="disableActionButtons"
            v-tooltip.bottom="'Open'"
            icon="pi pi-external-link"
            class="p-button-text p-button-icon-only p-button-rounded"
            @click="open(slotProps.data.id)"
          />
          <Button
            v-if="!readonly"
            :disabled="disableActionButtons"
            v-tooltip.bottom="'Edit'"
            icon="pi pi-pencil"
            class="p-button-text p-button-icon-only p-button-rounded p-button-secondary"
            @click="displayForm(slotProps.data)"
          />
          <Button
            v-if="!readonly"
            :disabled="disableActionButtons"
            v-tooltip.bottom="'Delete'"
            icon="pi pi-trash"
            class="p-button-text p-button-icon-only p-button-rounded p-button-danger"
            @click="deleteFile(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>

    <Button
      v-if="!readonly"
      v-show="!showForm"
      label="Add new file"
      icon="pi pi-plus"
      class="p-button-outlined p-button-rounded p-mt-4"
      :disabled="isSaving"
      @click="displayForm"
    />

    <div v-if="showForm" class="p-mt-5">
      <ValidationObserver ref="file-form-validation-observer" disabled>
        <div class="p-formgrid p-grid p-fluid">
          <ValidatedInput v-if="!isEdit" label="Add new file" class="p-col-3">
            <Dropdown
              v-model="form.type"
              :options="fileTypes"
              optionLabel="label"
              optionValue="value"
            />
          </ValidatedInput>

          <ValidatedInput rules="required" label="Description">
            <InputText type="text" v-model="form.description" />
          </ValidatedInput>
        </div>

        <template v-if="form.type === 'link'">
          <div class="p-formgrid p-grid">
            <ValidatedInput
              label="Link"
              class="p-field p-col"
              :rules="{
                url: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                required: true,
              }"
            >
              <InputText type="url" v-model="form.url" />
            </ValidatedInput>
          </div>
        </template>

        <div v-else-if="!isEdit" class="p-formgrid p-grid p-mb-3">
          <div class="file-upload p-col p-mr-4">
            <FileUpload
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              chooseLabel="Browse"
              uploadLabel="Upload"
              invalidFileLimitMessage="You could only upload one file at a time."
              invalidFileSizeMessage="File should be smaller than 2 MB."
              invalidFileTypeMessage="Please select a valid word, pdf or image (in jpg or png format) file."
              :customUpload="true"
              :disabled="isSaving"
              :fileLimit="1"
              :maxFileSize="2 * 1000000"
              :multiple="false"
              :showCancelButton="false"
              :showUploadButton="false"
              @select="updateFile"
              @clear="clearFile"
              @hook:mounted="addEventListeners"
            >
              <template #empty>
                <Button
                  label="Browse"
                  icon="pi pi-folder-open"
                  class="p-button-outlined p-button-rounded p-mb-2"
                  @click="openFileBrowser"
                />
                <div class="p-mb-2">or</div>
                <div>Drag &amp; drop a valid word or pdf file here.</div>
              </template>
            </FileUpload>
            <span v-if="showFileError" class="form-field-error-message">
              This field is required.
            </span>
          </div>
        </div>
      </ValidationObserver>
      <Button
        :label="isEdit ? 'Update' : isLink ? 'Add file' : 'Upload file'"
        :icon="`pi ${
          isSaving
            ? 'pi-spin pi-spinner'
            : isEdit
            ? 'pi-save'
            : isLink
            ? 'pi-plus'
            : 'pi-cloud-upload'
        }`"
        class="p-button p-button-rounded p-mr-2"
        :disabled="isSaving"
        @click="save"
      />
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-outlined p-button-rounded p-button-secondary"
        :disabled="isSaving"
        @click="hideForm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FileUpload from "primevue/fileupload";
import { accountFilesApi } from "@/apis/account-files";
import { AccountFile } from "@/interfaces";
import "@/assets/styles/file-upload.css";

@Component({
  components: {
    FileUpload,
  },
})
export default class FilesEdit extends Vue {
  @Prop() public readonly readonly: boolean;

  public accountId: number | undefined;
  public files: Array<AccountFile & { icon: string }> = [];
  public isListLoading: boolean = false;
  public isDeleting: boolean = false;
  public isSaving: boolean = false;
  public showForm: boolean = false;
  public showFileError: boolean = false;
  public fileTypes = [
    {
      label: "Link",
      value: "link",
    },
    {
      label: "Contract Upload",
      value: "file",
    },
  ];
  public form = {
    id: undefined,
    type: "link",
    url: "",
    description: "",
  };
  public file: File | undefined;

  public get disableActionButtons() {
    return this.isDeleting || this.isSaving;
  }

  public get isLink() {
    return this.form.type === "link";
  }

  public get isEdit() {
    return this.form.id !== undefined;
  }

  public displayForm(file: AccountFile = undefined) {
    this.showForm = true;

    if (file) {
      this.form = {
        id: file.id,
        type: file.file_key ? "file" : "link",
        url: file.url,
        description: file.description,
      };
    }
  }

  public hideForm() {
    this.showForm = false;
    this.form = {
      id: undefined,
      type: "link",
      url: "",
      description: "",
    };
  }

  public openFileBrowser(event) {
    event.target
      .closest(".file-upload")
      .querySelector("input[type='file']")
      .click();
  }

  public updateFile(event) {
    this.file = event.files[0];
    if (this.file !== undefined) {
      this.showFileError = false;
    }
  }

  public clearFile() {
    this.file = undefined;
  }

  public async deleteFile(fileId: number) {
    this["$dialog"]
      .confirm(
        "Are you sure you want to delete this file",
        this["$deleteOptions"]
      )
      .then(async () => {
        this.isDeleting = true;
        const response = await accountFilesApi.deleteFile(
          this.accountId,
          fileId
        );
        if (response && response.status === 200) {
          const fileIndex = this.files.findIndex((file) => file.id === fileId);
          this.files.splice(fileIndex, 1);
          this.$toast.add({
            severity: "success",
            detail: "File successfully deleted.",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            detail: "Failed to delete file.",
            life: 3000,
          });
        }
        this.isDeleting = false;
      });
  }

  public async open(fileId: number) {
    const response = await accountFilesApi.getFile(this.accountId, fileId);
    if (response && response.status === 200) {
      const { file_key, url } = response.data;
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      this.$toast.add({
        severity: "error",
        detail: "Failed to get file URL.",
        life: 3000,
      });
    }
  }

  public async save(event) {
    await this.$refs["file-form-validation-observer"]["validate"]();
    if (!this.$refs["file-form-validation-observer"]["flags"].valid) {
      if (!this.isLink && !this.file) {
        this.showFileError = true;
      }
      return;
    }

    this.isSaving = true;

    let response;
    if (this.isEdit) {
      response = await accountFilesApi.updateFile(
        this.accountId,
        this.form.id,
        this.form.description.trim(),
        this.isLink ? this.form.url.trim() : undefined
      );
    } else if (this.isLink) {
      response = await accountFilesApi.addFileLink(
        this.accountId,
        this.form.description.trim(),
        this.form.url.trim()
      );
    } else {
      response = await accountFilesApi.uploadFile(
        this.accountId,
        this.form.description.trim(),
        this.file
      );
    }
    if (response && response.status === 200) {
      this.getFileList();
      this.showForm = false;
      this.form = {
        id: undefined,
        type: "link",
        url: "",
        description: "",
      };
      this.$toast.add({
        severity: "success",
        detail: "File successfully saved.",
        life: 3000,
      });
    } else {
      this.$toast.add({
        severity: "error",
        detail: "Failed to save file.",
        life: 3000,
      });
    }
    this.isSaving = false;
  }

  public getIcon(key: string | null) {
    if (!key) {
      return "pi-link";
    }
    if (key.includes("pdf")) {
      return "pi-file-pdf";
    }

    return key.includes("doc") ? "pi-file-word" : "pi-image";
  }

  public getFileList() {
    this.isListLoading = true;
    accountFilesApi.listFiles(this.accountId).then((response) => {
      if (response && response.status === 200) {
        this.files = response.data.map((file) => {
          return {
            ...file,
            icon: this.getIcon(file.file_key),
          };
        });
      } else {
        this.$toast.add({
          severity: "error",
          detail: "Failed to retrieve files for this account.",
          life: 3000,
        });
      }
      this.isListLoading = false;
    });
  }

  public addEventListeners() {
    const dropzone = document.getElementsByClassName("p-fileupload-content")[0];
    dropzone.addEventListener("dragover", (event) => {
      dropzone.classList.add("dragging");
    });
    dropzone.addEventListener("dragleave", (event) => {
      dropzone.classList.remove("dragging");
    });
    dropzone.addEventListener("drop", (event) => {
      dropzone.classList.remove("dragging");
    });
  }

  public created() {
    this.accountId = parseInt(this.$route.params.id, 0);
    this.getFileList();
  }
}
</script>

<style scoped>
.account-files {
  padding: 2rem;
}

.file-link {
  text-decoration: none;
}

.file-upload {
  margin-top: -10px;
}
</style>
