<template>
  <Dialog
    :closeOnEscape="false"
    :visible.sync="displayMaximizableSync"
    v-if="!(createView && readonly)"
    maximizable
    :closable="false"
    modal
    class="mastersheet-dialog"
  >
    <template #header>
      <div class="p-d-flex p-ai-center p-jc-start">
        <div class="mastersheet-name-block" />
        <div class="mastersheet-name-text">
          <span>{{ accountForm.name }}</span>
          <span style="margin-left: 1.125rem; margin-right: 1.125rem">-</span>
          <span>{{ accountForm.type }}</span>
          <span v-if="draftId" class="p-ml-2">(Draft)</span>
        </div>
      </div>
    </template>
    <div class="p-grid p-m-0 content-wrapper">
      <button
        class="close-button"
        @click.prevent="close"
        :disabled="saving || deleting"
      >
        <i class="pi pi-times-circle"></i>
      </button>
      <div class="sidebar-nav">
        <nav class="p-d-flex p-flex-column">
          <div
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'account-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'account-tab'"
          >
            <div>
              <span :class="toggleClass('account-tab')"> Account </span>
              <RequiredMark />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-if="accountForm.type === 'YouTube Channel'"
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'youtube-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'youtube-tab'"
          >
            <div>
              <span :class="toggleClass('youtube-tab')"> YouTube </span>
              <RequiredMark />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-else-if="accountForm.type === 'China Platform'"
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="
              currentActiveTab === 'china-platform-tab' ? 'active-tab' : ''
            "
            @click="currentActiveTab = 'china-platform-tab'"
          >
            <div>
              <span :class="toggleClass('china-platform-tab')">
                China Platform
              </span>
              <RequiredMark />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-if="
              accountForm.type === 'YouTube Channel' ||
              accountForm.type === 'China Platform'
            "
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'category-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'category-tab'"
          >
            <div>
              <span :class="toggleClass('category-tab')"> Categories </span>
              <RequiredMark class="no-text-underline" />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-else-if="accountForm.type === 'Clip Licensing'"
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'clip-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'clip-tab'"
          >
            <div>
              <span :class="toggleClass('clip-tab')"> Clip License </span>
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-else-if="accountForm.type === 'Podcast'"
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'podcast-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'podcast-tab'"
          >
            <div>
              <span :class="toggleClass('podcast-tab')"> Podcast </span>
              <RequiredMark />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'creator-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'creator-tab'"
          >
            <div>
              <span :class="toggleClass('creator-tab')"> Creator </span>
              <RequiredMark class="no-text-underline" />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'manager-tab' ? 'active-tab' : ''"
            @click="
              currentActiveTab = 'manager-tab';
              managerRoleNotice();
            "
          >
            <div>
              <span :class="toggleClass('manager-tab')"> Managers </span>
              <RequiredMark class="no-text-underline" />
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'payment-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'payment-tab'"
          >
            <div>
              <span :class="toggleClass('payment-tab')"> Payment Info </span>
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'social-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'social-tab'"
          >
            <div>
              <span :class="toggleClass('social-tab')"> Social Media </span>
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-if="!createView"
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'files-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'files-tab'"
          >
            <div>
              <span :class="toggleClass('files-tab')"> Contract Upload </span>
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
          <div
            v-if="!createView"
            class="sidebar-nav-link p-d-flex p-ai-center p-jc-between"
            :class="currentActiveTab === 'comments-tab' ? 'active-tab' : ''"
            @click="currentActiveTab = 'comments-tab'"
          >
            <div>
              <span :class="toggleClass('comments-tab')"> Comments </span>
            </div>
            <i class="pi pi-angle-right tab-icon" />
          </div>
        </nav>
      </div>
      <div class="p-col p-mt-4" ref="account-form-container">
        <div class="p-d-flex p-ai-center p-jc-between">
          <span class="account-edit-notice">
            <template v-if="currentActiveTab !== 'files-tab'">
              Tabs marked with asterisk (*) contain required data fields
            </template>
            <template v-else>
              Users can upload contracts or input links of files related to the
              account.
            </template>
          </span>
          <div class="p-d-flex" style="margin-right: 4rem">
            <Button
              v-if="createView && !draftId"
              icon="pi pi-times"
              label="Cancel"
              @click="close"
              :class="`p-button-danger custom-button custom-button-right-margin p-button-rounded`"
            />
            <Button
              v-if="
                !createView && !readonly && currentActiveTab !== 'files-tab'
              "
              :icon="deleting ? 'pi pi-spin pi-spinner' : 'pi pi-trash'"
              :disabled="deleting"
              label="Delete"
              type="button"
              class="p-button-danger custom-button custom-button-right-margin p-button-rounded"
              @click="confirmDelete"
            />
            <Button
              v-if="createView && draftId"
              :icon="deletingDraft ? 'pi pi-spin pi-spinner' : 'pi pi-trash'"
              :disabled="deletingDraft"
              label="Delete Draft"
              @click="deleteDraft"
              :class="`p-button-danger custom-button custom-button-right-margin p-button-rounded`"
            />
            <Button
              v-if="createView"
              :icon="savingDraft ? 'pi pi-spin pi-spinner' : 'pi pi-file-edit'"
              :label="draftId ? 'Update Draft' : 'Save as Draft'"
              :disabled="savingDraft || deletingDraft"
              class="p-button-main custom-button custom-button-right-margin p-button-rounded"
              @click="saveDraft"
            />
            <Button
              :icon="saving ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
              v-if="!readonly"
              label="Save"
              :disabled="saving || deleting"
              type="submit"
              class="p-button-main custom-button custom-button-right-margin p-button-rounded"
            />
            <Button
              v-if="!createView"
              :icon="exporting ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
              :disabled="exporting || deleting"
              label="Export"
              type="button"
              @click="doExport('single')"
              class="p-button-main custom-button p-button-rounded"
            />
          </div>
        </div>
        <AccountEdit
          v-show="currentActiveTab === 'account-tab'"
          :account.sync="accountForm"
          :create-view="createView"
          :readonly="readonly"
          @dashboard-user-updating="setDashboardUserIsLoading"
          @dashboard-user-updated="updateDashboardUser"
        />
        <YoutubeEdit
          v-if="accountForm.type === 'YouTube Channel'"
          v-show="currentActiveTab === 'youtube-tab'"
          ref="youtube-edit"
          :youtube.sync="accountForm.youtube_channel"
          :thumbnail-url="youtubeThumbnailUrl"
          :create-view="createView"
          :readonly="readonly"
          :is-channel-list-loading="isDashboardUserLoading"
          :linked-channels="linkedChannels"
        />
        <CategoryEdit
          v-if="accountForm.type === 'YouTube Channel'"
          v-show="currentActiveTab === 'category-tab'"
          :network.sync="accountForm.youtube_channel"
          :create-view="createView"
          :readonly="readonly"
        />
        <CategoryEdit
          v-if="accountForm.type === 'China Platform'"
          v-show="currentActiveTab === 'category-tab'"
          :network.sync="accountForm.china_platform"
          :create-view="createView"
          :readonly="readonly"
        />
        <ClipEdit
          v-if="accountForm.type === 'Clip Licensing'"
          v-show="currentActiveTab === 'clip-tab'"
          :clip.sync="accountForm.clip_license"
          :create-view="createView"
          :readonly="readonly"
        />
        <PodcastEdit
          v-if="accountForm.type === 'Podcast'"
          v-show="currentActiveTab === 'podcast-tab'"
          :podcast.sync="accountForm.podcast"
          :create-view="createView"
          :readonly="readonly"
        />
        <ChinaPlatformEdit
          v-if="accountForm.type === 'China Platform'"
          v-show="currentActiveTab === 'china-platform-tab'"
          :china-platform.sync="accountForm.china_platform"
          :create-view="createView"
          :readonly="readonly"
        />
        <CreatorEdit
          v-show="currentActiveTab === 'creator-tab'"
          :creator-id.sync="accountForm.creator_id"
          :create-view="createView"
          :readonly="readonly"
        />
        <ManagersEdit
          ref="managers-edit"
          :create-view="createView"
          :account-id="accountForm.id || parseInt(accountId, 10)"
          v-show="currentActiveTab === 'manager-tab'"
          :readonly="readonly"
        />
        <PaymentEdit
          v-show="currentActiveTab === 'payment-tab'"
          :payment.sync="accountForm.payment_info"
          :splits.sync="accountForm.splits"
          :create-view="createView"
          :readonly="readonly"
        />
        <SocialEdit
          v-show="currentActiveTab === 'social-tab'"
          :social.sync="accountForm.social_media"
          :create-view="createView"
          :readonly="readonly"
        />
        <FilesEdit
          :readonly="readonly"
          v-if="!createView"
          v-show="currentActiveTab === 'files-tab'"
        />
        <CommentsEdit
          v-if="!createView"
          v-show="currentActiveTab === 'comments-tab'"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  PropSync,
  Emit,
} from "vue-property-decorator";
import { accountDraftsApi } from "@/apis/account-drafts";
import AccountEdit from "@/views/mastersheet/MastersheetEdit/AccountEdit.vue";
import CreatorEdit from "@/views/mastersheet/MastersheetEdit/CreatorEdit.vue";
import ClipEdit from "@/views/mastersheet/MastersheetEdit/ClipEdit.vue";
import PodcastEdit from "@/views/mastersheet/MastersheetEdit/PodcastEdit.vue";
import ChinaPlatformEdit from "@/views/mastersheet/MastersheetEdit/ChinaPlatformEdit.vue";
import PaymentEdit from "@/views/mastersheet/MastersheetEdit/PaymentEdit.vue";
import YoutubeEdit from "@/views/mastersheet/MastersheetEdit/YoutubeEdit.vue";
import SocialEdit from "@/views/mastersheet/MastersheetEdit/SocialEdit.vue";
import CategoryEdit from "@/views/mastersheet/MastersheetEdit/CategoryEdit.vue";
import FilesEdit from "@/views/mastersheet/MastersheetEdit/FilesEdit.vue";
import CommentsEdit from "@/views/mastersheet/MastersheetEdit/CommentsEdit.vue";
import ManagersEdit from "@/views/mastersheet/MastersheetEdit/ManagersEdit.vue";
import { DashboardUser } from "@/interfaces";
import {
  dispatchCreateAccount,
  dispatchGetAccountFull,
  dispatchUpdateAccount,
  dispatchDeleteAccount,
} from "@/store/account/actions";
import { readAccountFull } from "@/store/account/getters";

@Component({
  components: {
    AccountEdit,
    CreatorEdit,
    ClipEdit,
    PodcastEdit,
    ChinaPlatformEdit,
    PaymentEdit,
    YoutubeEdit,
    SocialEdit,
    CategoryEdit,
    FilesEdit,
    CommentsEdit,
    ManagersEdit,
  },
})
export default class MastersheetEdit extends Vue {
  @Prop() public readonly activeTab!: string;
  @Prop() public readonly accountId!: number;
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;
  @Prop() public readonly exporting!: boolean;
  @Prop() public readonly draftId!: number;

  @PropSync("displayMaximizable") public displayMaximizableSync!: boolean;

  public currentActiveTab = !this.createView ? this.activeTab : "account-tab";
  public accountForm: any = {
    youtube_channel: {},
    clip_license: {},
    payment_info: {},
    podcast: {},
    china_platform: {},
    splits: {
      creator: 0,
      collab: 0,
      third_party: 0,
    },
    social_media: {},
  };
  public youtubeThumbnailUrl: string | null = null;
  public dashboardUser: DashboardUser | null = null;
  public isDashboardUserLoading = false;
  public saving: boolean = false;
  public deleting: boolean = false;
  public savingDraft: boolean = false;
  public deletingDraft: boolean = false;

  public get account() {
    return readAccountFull(this.$store);
  }

  public get linkedChannels() {
    return this.dashboardUser?.youtube_channels ?? [];
  }

  public async close() {
    this.displayMaximizableSync = false;
  }

  public toggleClass(e) {
    if (this.currentActiveTab === e) {
      return "router-link-active";
    }
    return "";
  }

  public async saveDraft() {
    if (!this.createView) {
      return;
    }

    this.savingDraft = true;

    const managers = this.$refs["managers-edit"]["members"].map((member) => {
      return {
        manager_id: member.id,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    const draft = {
      data: {
        account: this.accountForm,
        managers,
      },
    };

    let response = null;
    if (this.draftId === null) {
      response = await accountDraftsApi.createDraftAccount(draft);
    } else {
      response = await accountDraftsApi.updateDraftAccount(this.draftId, draft);
    }

    if (response && response.status === 200) {
      this.$toast.add({
        severity: "success",
        summary: `Draft successfully ${this.draftId ? "updated" : "saved"}.`,
        detail: "You may complete it within 7 days from now.",
        life: 6000,
      });
      this.$emit("draft-saved", response.data.id);
    } else {
      this.$toast.add({
        severity: "error",
        summary: "Could not save draft",
        detail: "Something went wrong.",
        life: 6000,
      });
    }

    this.savingDraft = false;
  }

  public async deleteDraft() {
    if (!this.createView && !this.draftId) {
      return;
    }

    this.deletingDraft = true;

    const response = await accountDraftsApi.deleteDraftAccount(this.draftId);
    if (response && response.status === 200) {
      setTimeout(() => {
        this.$toast.add({
          severity: "success",
          summary: "Draft successfully deleted.",
          life: 6000,
        });
      }, 100);
      this.$emit("draft-deleted");
    } else {
      this.$toast.add({
        severity: "error",
        summary: "Could not delete draft",
        detail: "Something went wrong.",
        life: 6000,
      });
    }

    this.deletingDraft = false;
  }

  public confirmSave() {
    const t = this;
    const hasDuplicate =
      t.$refs["youtube-edit"] && t.$refs["youtube-edit"]["isDuplicate"];
    this["$dialog"]
      .confirm(
        hasDuplicate
          ? "An account for this channel ID already exists. Are you sure you wish to continue?"
          : "Are you sure you want to save the changes?",
        this["$saveOptions"]
      )
      .then(async function () {
        t.saving = true;

        // reset empty date fields to null
        if (t.accountForm.expiration_date === "") {
          t.accountForm.expiration_date = null;
        }
        if (t.accountForm.initial_contract_date === "") {
          t.accountForm.initial_contract_date = null;
        }
        if (t.accountForm.first_contact_date === "") {
          t.accountForm.first_contact_date = null;
        }
        if (t.accountForm.youtube_channel?.unlinked_date === "") {
          t.accountForm.youtube_channel.unlinked_date = null;
        }
        if (t.accountForm.youtube_channel?.monetization_start_date === "") {
          t.accountForm.youtube_channel.monetization_start_date = null;
        }

        // reset numeric feilds to null
        if (t.accountForm.dashboard_id === "") {
          t.accountForm.dashboard_id = null;
        }
        if (t.accountForm.youtube_channel?.subs_at_signing === "") {
          t.accountForm.youtube_channel.subs_at_signing = null;
        }
        if (t.accountForm.youtube_channel?.subscribers === "") {
          t.accountForm.youtube_channel.subscribers = null;
        }
        if (t.accountForm.youtube_channel?.views === "") {
          t.accountForm.youtube_channel.views = null;
        }
        if (t.accountForm.china_platform?.subs_at_signing === "") {
          t.accountForm.china_platform.subs_at_signing = null;
        }
        if (t.accountForm.china_platform?.subscribers === "") {
          t.accountForm.china_platform.subscribers = null;
        }
        if (t.accountForm.china_platform?.views === "") {
          t.accountForm.china_platform.views = null;
        }

        if (t.createView) {
          const response = await dispatchCreateAccount(t.$store, {
            data: t.accountForm,
          });
          if (response) {
            const newId = response.id;
            const newResponse = await t.$refs["managers-edit"]["saveManagers"](
              newId
            );
            if (newResponse) {
              await t.$router.push(`/mastersheet/${newId}`);
              t["$toast"].add({
                severity: "success",
                detail:
                  "Account successfully added. You may now upload or link files to it.",
                life: 6000,
              });
            }
            t.accountForm.id = newId;
            t.$emit("refresh-accounts");
            t.$emit("new-account-created", newId);
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not create account",
              detail: "Something went wrong",
              life: 6000,
            });
          }
        } else {
          const response = await dispatchUpdateAccount(t.$store, {
            id: t.accountForm.id,
            data: t.accountForm,
          });
          if (response) {
            const response = await t.$refs["managers-edit"]["saveManagers"]();
            if (response) {
              t["$toast"].add({
                severity: "success",
                detail: "Account successfully updated.",
                life: 3000,
              });
            } else {
              t["$toast"].add({
                severity: "error",
                summary: "Could not update account's managers",
                detail: "Something went wrong.",
                life: 6000,
              });
            }
            t.$emit("refresh-accounts");
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not update account",
              detail: "Something went wrong.",
              life: 6000,
            });
          }
        }
        t.saving = false;
      });
  }

  public managerRoleNotice() {
    this["$toast"].removeGroup("bl");
    this["$toast"].add({
      severity: "info",
      summary: "About roles editing",
      group: "bl",
      detail:
        "Only users with Super Admin permission can change the roles of managers here.",
    });
  }

  public async confirmDelete() {
    const t = this;
    this["$dialog"]
      .confirm(
        "Make sure you only delete accounts with incorrect information.\n In case the account's contract is only terminated, please mark the account as 'Terminated' in the Contract Status instead.",
        this["$deleteOptions"]
      )
      .then(async function () {
        t.deleting = true;
        const response = await dispatchDeleteAccount(t.$store, {
          id: t.accountId,
        });
        if (response && response.status === 200) {
          // TODO: proper navigation needed
          t.$emit("refresh-accounts", true);
          t.displayMaximizableSync = false;
          setTimeout(() => {
            t.$toast.add({
              severity: "success",
              detail: "Account successfully deleted.",
              life: 3000,
            });
          }, 100);
        } else {
          t.$toast.add({
            severity: "error",
            summary: "Could not delete account",
            detail: "Something went wrong. Please reach out to support.",
            life: 3000,
          });
        }
        t.deleting = false;
      });
  }

  public setDashboardUserIsLoading() {
    this.isDashboardUserLoading = true;
  }

  public updateDashboardUser(dashboardUser) {
    this.dashboardUser = dashboardUser;
    this.isDashboardUserLoading = false;
  }

  @Emit("do-export")
  public doExport(e) {
    return;
  }

  @Watch("accountForm.youtube_channel.id")
  public updatePaymentInfo() {
    if (
      !this.createView ||
      !this.accountForm.youtube_channel.id ||
      !this.dashboardUser
    ) {
      return;
    }
    const selectedChannel = this.dashboardUser.youtube_channels.find(
      (channel) =>
        channel.youtube_channel_id === this.accountForm.youtube_channel.id
    );

    if (selectedChannel) {
      this.accountForm.splits.creator = selectedChannel.splits.user ?? 0;
      this.accountForm.splits.collab = selectedChannel.splits.network ?? 0;
      this.accountForm.splits.third_party = selectedChannel.splits.other ?? 0;

      if (this.dashboardUser.payment_method) {
        this.accountForm.payment_info.payment_method =
          this.dashboardUser.payment_method;
      }
      const paypalEmail = this.dashboardUser.payment_details?.paypal?.email;
      if (paypalEmail) {
        this.accountForm.payment_info.paypal_email = paypalEmail.trim();
      }
      const accountNumber =
        this.dashboardUser.payment_details?.wire?.account_number;
      if (accountNumber) {
        this.accountForm.payment_info.bank_account_number = accountNumber;
      }
      const swiftCode = this.dashboardUser.payment_details?.wire?.swift_code;
      if (swiftCode) {
        this.accountForm.payment_info.swift_code = swiftCode;
      }
      const bankAddress =
        this.dashboardUser.payment_details?.wire?.bank_address;
      if (bankAddress) {
        this.accountForm.payment_info.bank_address = bankAddress;
      }
    }
  }

  public async mounted() {
    if (this.createView) {
      window["analytics"]["page"]("/mastersheet/create");
    }
    this["$toast"].add({
      severity: "info",
      summary: "Fill out all needed info",
      group: "bl",
      detail:
        "Go through and fill out the required fields in each tab (if applicable). Remember to assign a creator in order to save the account.",
    });
    if (this.createView) {
      if (this.draftId) {
        const response = await accountDraftsApi.getDraftAccount(this.draftId);
        if (response && response.status === 200) {
          const draft = response.data.data;
          this.accountForm = draft.account;
          this.$refs["managers-edit"]["fetchManagers"](draft.managers);
        } else {
          this.displayMaximizableSync = false;
          this.$router.push("/mastersheet");
        }
      } else {
        this.accountForm = {
          type: "YouTube Channel",
          youtube_channel: {},
          clip_license: {},
          podcast: {},
          china_platform: {},
          payment_info: {},
          splits: {
            creator: 0,
            collab: 0,
            third_party: 0,
          },
          social_media: {},
        };
      }
    } else {
      await dispatchGetAccountFull(this.$store, { id: this.accountId });
      if (this.account.id) {
        this.accountForm = JSON.parse(JSON.stringify(this.account));
        this.accountForm.splits = {
          creator: this.accountForm.splits.creator || 0,
          collab: this.accountForm.splits.collab || 0,
          third_party: this.accountForm.splits.third_party || 0,
        };
        this.youtubeThumbnailUrl =
          this.accountForm.youtube_channel__thumbnail_url;
        delete this.accountForm.youtube_channel__thumbnail_url;
      } else {
        this.$router.push("/matersheet/youtube");
      }
    }
  }

  public beforeDestroy() {
    this["$toast"].removeAllGroups();
  }
}
</script>

<style>
.close-button {
  position: absolute;
  right: 1.25rem;
  top: 0.75rem;
  border-radius: 50%;
  color: white;
  background-color: transparent;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  padding-top: 2px;
}

.close-button:disabled {
  color: #495057;
}

.close-button:hover:not([disabled]) {
  background-color: #e9ecef;
  color: #495057;
  border: transparent;
  font-weight: bold;
}

.close-button:focus:not([disabled]) {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #a6d5fa;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.mastersheet-name-text {
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
}

.mastersheet-name-block {
  width: 0.313rem;
  height: 1.063rem;
  margin: 0.188rem 1.125rem 0.125rem 0;
  border-radius: 4px;
  background-color: var(--rat-grey);
}

.account-edit-notice {
  font-style: italic;
  font-size: 0.85rem;
  color: var(--rat-grey);
  margin-left: 2.5rem;
}

.mastersheet-dialog {
  min-width: 96vw;
  height: 95vh;
}

.mastersheet-dialog.p-dialog-mask {
  width: 100vw;
  height: 100vh;
}

.mastersheet-dialog.p-dialog-maximized {
  min-width: 100%;
  min-height: 100%;
}

.mastersheet-dialog .p-dialog-header {
  padding: 1.25rem 2.481rem 1.188rem 1.625rem;
  background-color: var(--dark-grey);
  height: 3.813rem;
  width: 100%;
}

.mastersheet-dialog .p-dialog-content {
  height: 100%;
  padding: 0;
}

.mastersheet-dialog.p-dialog .p-dialog-header .p-dialog-header-icons {
  margin-right: 1rem;
  margin-top: -0.5rem;
}

.mastersheet-dialog.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  .p-dialog-header-icon.p-dialog-header-maximize.p-link
  .p-dialog-header-maximize-icon.pi.pi-window-maximize {
  color: white;
}

.mastersheet-dialog.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  .p-dialog-header-icon.p-dialog-header-maximize.p-link:hover
  .p-dialog-header-maximize-icon.pi.pi-window-maximize {
  color: #495057;
}

.mastersheet-dialog.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  .p-dialog-header-icon.p-dialog-header-maximize.p-link
  .p-dialog-header-maximize-icon.pi.pi-window-minimize {
  color: white;
}

.mastersheet-dialog.p-dialog
  .p-dialog-header
  .p-dialog-header-icons
  .p-dialog-header-icon.p-dialog-header-maximize.p-link:hover
  .p-dialog-header-maximize-icon.pi.pi-window-minimize {
  color: #495057;
}

.mastersheet-dialog .content-wrapper {
  height: 100%;
}

.mastersheet-dialog .sidebar-nav {
  background-color: #ecf5ff;
  padding: 0;
  border-right: 1px solid #c0c4cc;
}

.mastersheet-dialog .sidebar-nav-link {
  margin: 0;
  padding: 0.8rem 2rem;
  border-bottom: 1px solid #c0c4cc;
}

.mastersheet-dialog .sidebar-nav-link.active-tab {
  background-color: #ebeef5;
}

.mastersheet-dialog .tab-icon {
  font-size: 0.8rem;
  font-weight: normal;
}
</style>
