.tag-span:not(:last-child) {
  margin-right: 4px;
}

.ag-tool-panel-wrapper {
  margin-top: 4rem;
  width: 400px !important;
  background: white !important;
}

.ag-theme-alpine .ag-status-bar {
  max-height: 36px;
  line-height: 1;
}

.ag-theme-alpine .ag-rtl .ag-side-bar-left .ag-side-button-button,
.ag-theme-alpine .ag-ltr .ag-side-bar-right .ag-side-button-button {
  border-left: none;
}

.ag-side-button.ag-selected .ag-side-button-button:hover {
  color: var(--white);
}

.ag-side-button.ag-selected {
  background-color: #4962f3;
  border-bottom: solid 0.5px #4962f3;
  color: #ffffff;
  border-left: none !important;
}

.ag-theme-alpine .ag-side-buttons {
  padding-top: 0 !important;
  background-color: #ecf5ff !important;
}

.ag-theme-alpine .ag-side-buttons .ag-side-button {
  border-bottom: solid 0.5px #babfc7;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #f8f8f8;
}

.ag-theme-alpine .ag-row {
  font-family: "Source Sans Pro";
}

.ag-theme-alpine .ag-header-row:nth-child(2) .ag-header-cell {
  border-top: 0;
}

.mastersheet-table-wrapper {
  height: calc(100vh - 6.9rem);
  width: 100%;
  z-index: 1;
}

.mastersheet-tabs .nav {
  margin-left: 13.8rem;
  margin-top: 1rem;
  margin-bottom: 0px;
}

.mastersheet-tabs .nav-item a {
  background: rgba(169, 211, 255, 0.5);
}

.mastersheet-tabs .nav-link {
  height: 37px;
  padding: 0.6rem 1rem 0;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: normal;
  color: #222222;
}

.mastersheet-tabs .nav-link.mastersheet-tab-active {
  background-color: #2d3142;
  color: var(--white);
  font-weight: 700;
}

.mastersheet-table-column {
  width: 20rem !important;
}

.mastersheet-table-wrapper .p-column-filter {
  max-width: 100% !important;
  margin-right: auto !important;
}

.mastersheet-table-wrapper .p-filter-column {
  padding-top: 10px !important;
  background-color: white !important;
  max-width: 100% !important;
}

.channel-thumbnail {
  margin-left: 3rem;
  margin-top: 2.5rem;
  height: 220px;
  width: 220px;
  padding: 1rem;
  border-radius: 100%;
}

.flex-grow {
  flex-grow: 1;
}
